import React from "react"
import Box from "@material-ui/core/Box"
import { Button } from "@material-ui/core"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  footerButton: {
    fontSize: "1rem",
    letterSpacing: "0.1rem",
    color: "#FFF",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <footer>
      <Box width={1} bgcolor="#252527">
        <Box pt={2} textAlign="center">
          <Button component={Link} to="/" className={classes.footerButton}>
            Home
          </Button>
          <Button
            component={Link}
            to="/services"
            className={classes.footerButton}
          >
            Services
          </Button>
          <Button
            component={Link}
            to="/contact"
            className={classes.footerButton}
          >
            Contact
          </Button>
        </Box>
        <Box
          fontSize="h5.fontSize"
          letterSpacing={1.5}
          lineHeight={1.5}
          pt={2}
          pb={2}
          color="#f7fbfb"
          textAlign="center"
        >
          © {new Date().getFullYear()}, All Rights Reserved. The Dashboard
          Company, LLC.
        </Box>
      </Box>
    </footer>
  )
}
