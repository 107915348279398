import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Footer from '../footer'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 auto',
  },
}))

const Layout = ({ children }) => {
  const classes = useStyles()
  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
