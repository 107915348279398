import React from "react"
import { Link } from "gatsby"
import Logo from "../images/dashboard_logo.svg"
import Box from "@material-ui/core/Box"
import CssBaseline from "@material-ui/core/CssBaseline"
import MenuIcon from "@material-ui/icons/Menu"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

const ITEM_HEIGHT = 48

function MobileNav() {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)

  function handleMenu(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }
  return (
    <>
      <CssBaseline />
      <Box
        component="span"
        display={{ xs: "block", sm: "block", md: "none" }}
        width={1}
      >
        <Box
          padding={3}
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            order={1}
            component={Link}
            to="/"
            width={200}
            alignSelf="flex-start"
          >
            <Logo />
          </Box>
          <Box order={2} alignSelf="flex-end">
            <IconButton
              color="primary.dark"
              aria-label="menu"
              aria-haspopup="true"
              aria-controls="menu-app"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-app"
              anchorEl={anchorEl}
              keepMounted
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 5.5,
                  width: 300,
                  backgroundColor: "#f5f5f5",
                },
              }}
            >
              <MenuItem component={Link} to="/" onClick={handleClose}>
                Home
              </MenuItem>
              <MenuItem component={Link} to="/services" onClick={handleClose}>
                Services
              </MenuItem>
              <MenuItem component={Link} to="/contact" onClick={handleClose}>
                Contact
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default MobileNav
