import React from "react"
import { Link } from "gatsby"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Logo from "../images/dashboard_logo.svg"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  headerButton: {
    fontSize: "1rem",
    letterSpacing: "0.1rem",
  },
})

export default function Header() {
  const classes = useStyles()
  return (
    <>
      <Box
        component="span"
        display={{ xs: "none", sm: "none", md: "block" }}
        width={1}
      >
        <Box
          padding={3}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          maxWidth="90vw"
          margin="0 auto"
        >
          <Box component={Link} to="/" width={275} >
            <Logo />
          </Box>
          <Box alignSelf="flex-end" pt={{ xs: 7, md: 3 }} pb={2}>
            <Button component={Link} to="/" className={classes.headerButton}>
              Home
            </Button>
            <Button
              component={Link}
              to="/services"
              className={classes.headerButton}
            >
              Services
            </Button>
            <Button
              component={Link}
              to="/contact"
              className={classes.headerButton}
            >
              Contact
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
